<template>
  <b-card>
    <div class="d-flex justify-content-between mb-1 flex-sm-row  flex-column-reverse">
      <b-alert
          v-if="eventStart.day >0 || eventStart.minutes>0 || eventStart.hours>0"
          variant="warning"
          class="mb-0"
          show>
        <div class="alert-body">
          <feather-icon
              icon="ClockIcon"
              class="mr-50"/>
          {{ $t('This Event starts in') }}:
          <span v-if="eventStart.day">{{ eventStart.day }} {{ $t('day') }}, </span>
          <span v-if="eventStart.hours">{{ eventStart.hours }} {{ $t('hours') }} & </span>
          <span v-if="eventStart.minutes">   {{ eventStart.minutes }} {{ $t('minutes') }} </span>
        </div>
      </b-alert>
      <b-alert
          v-if="eventStart.day <= 0 && eventStart.minutes <= 0 && eventStart.hours <= 0"
          variant="warning"
          class="mb-0"
          show>
        <div class="alert-body">
          <feather-icon
              icon="ClockIcon"
              class="mr-50"/>
          {{ $t('This Event happened on the') }}:
          {{ pastEventDate }}
        </div>
      </b-alert>
      <b-button variant="danger"
                class="mb-1 mb-sm-0"
                :to="{name:'dashboard'}">
        {{ $t('Leave event room') }}
      </b-button>
    </div>
    <hr class="mb-0">
  </b-card>
</template>

<script>
import moment from 'moment'
import {
  BButton, BAlert,BCard
} from "bootstrap-vue";


export default {
  name: "EventHeader",

  components: {
    BButton,
    BAlert,
    BCard
  },
  props: {
    event: {
      type: Object || Array,
      default: {} || [],
    },
  },
  computed: {
    pastEventDate() {
      return moment(this.event.start_date)
          .format('DD.MM.YYYY')
    }
  },

  data() {
    return {
      eventStart: {
        day: 0,
        hours: 0,
        minutes: 0
      },
    }
  },
  methods: {
    setTimeToEvent() {
      if (this.event.start_date) {
        let eventTime = moment(this.event.start_date)
        let currentTime = moment();
        let duration = moment.duration(eventTime.diff(currentTime))
        duration = moment.duration(duration - 1000, 'milliseconds')
        this.eventStart.day = duration.days()
        this.eventStart.hours = duration.hours()
        this.eventStart.minutes = duration.minutes()
      }
    }
  },
  mounted() {
    this.setTimeToEvent()
  }
}
</script>

<style scoped>

</style>
