<template>
  <b-col cols="12" :class="videoCallClass">
    <b-card class="event-infos-card mt-2">
      <template #header>
        <h4 class="mb-0">{{ $t("Video Call") }}</h4>
        <div>
        </div>
      </template>
      <b-card class="text-center" v-if="!videoCall">
        <div v-if="timer && !videoCall">
          <countdown :time="timer * 60 * 1000 " @end="createVideo">
            <template slot-scope="props">
              <b-badge variant="warning" class="badge">
                {{ $t('Video call will be available') }}：{{ props.days }} {{ $t('Days') }}, {{ props.hours }} {{
                $t('Hours') }}, {{ props.minutes }} {{ $t('Minutes') }}, {{ props.seconds }} {{ $t('seconds') }}.
              </b-badge>
            </template>
          </countdown>
        </div>
        <div v-if="error_message">
          <b-badge variant="warning" class="badge">
            {{ error_message }}
          </b-badge>
        </div>
        <div class="mt-3">
          <video-off-icon size="4x" class="custom-class"></video-off-icon>
        </div>
      </b-card>
      <b-card header-border-variant="secondary" v-if="videoCall" v-html="whereByHtml"></b-card>
    </b-card>
  </b-col>
</template>
<script>
import {
  BCard,
  BButton,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BIcon,
  BSidebar,
  VBToggle,
  BCardHeader,
  BCardBody,
  BBadge,
  BCardFooter
} from "bootstrap-vue";
import { CheckCircleIcon } from "vue-feather-icons";
import { AlignJustifyIcon } from 'vue-feather-icons'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Ripple from "vue-ripple-directive";
import { VideoOffIcon } from 'vue-feather-icons'
export default {
  props: {
    eventId: {
      type: String,
      default: 0,
    },
    dimension: {
      type: Object,
      default: () => {},
    },
    event: {
      type: Object,
      default: () => {},
    },
  },
  components: {
   BCard,
    BButton,
    BCarousel,
    BCarouselSlide,
    BRow,
    BIcon,
    BCol,
    CheckCircleIcon,
    BSidebar,
    BCardHeader,
    BCardBody,
    BCardFooter,
    AlignJustifyIcon,
    VueSlickCarousel,
    BBadge,
    VideoOffIcon
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  data: function () {
    return {
      accessToken: "",
      slide: 0,
      sliding: null,
      videoCall: false,
      loading: false,
      room:null,
      whereByHtml:"",
      timer:0,
      error_message:"",
      videoCallClass: "order-1"
    };
  },
  mounted: function () {
    let videoRoom = document.createElement('script')
    videoRoom.setAttribute('src', 'https://cdn.srv.whereby.com/embed/v1.js')
    videoRoom.setAttribute("type", "module");
    document.head.appendChild(videoRoom)
    this.createVideo()
    this.timer = this.event.event_waiting_time;
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    async getBooking() {
      let bookingUrl = `/experts/bookings/${this.$route.params.bookingid}`;
      return await this.$http.get(bookingUrl);
    },
    createVideo() {
      this.timer = 0;
      this.loading = true;
      this.getBooking().then((data) => {
        if(data.data?.timeslots){
          data.data.timeslots.forEach(timeslot => {
            if (timeslot.is_current && timeslot.video_room && !this.videoCall) {
              this.videoCall = true;
              this.videoCallClass = 'order-0';
              this.room = timeslot.video_room.hostRoomUrl;
              let userData = JSON.parse(localStorage.getItem('userData'));
              this.whereByHtml = `<whereby-embed class="whereby-embed" displayName="${userData.fullName}" background=off room="${this.room}"></whereby-embed>`
            }
          });
        }
      }).catch((error)=>{
         this.error_message = error.response.data.waiting_details;
      });
    },
  },
};
</script>
<style lang="scss">
.whereby-embed { height: 700px; width: 100%; }
.carousel-item img {
  height: 60vh !important ;
  width: 100%;
}
</style>
