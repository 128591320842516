<template>
  <div v-if="currentItem.id">
    <b-row>
      <b-col cols="12">
        <EventHeader v-if="currentItem.id" :event="currentItem"></EventHeader>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="order-1">
        <event-info v-if="currentItem.id" :event="currentItem"></event-info>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="order-1">
        <event-file-upload v-if="currentItem.id" @file-display="viewFileLightBox" :event="currentItem" />
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="order-1">
        <chat v-if="currentItem.id" :event-id="currentItem.event.id" :booking-id="currentItem.id"></chat>
      </b-col>
      <video-chat v-if="currentItem.id" :event-id="currentItem.event.id" :event="currentItem"></video-chat>
    </b-row>
    <light-box :media="media" v-if="showLightBox" @onClosed="closeLightBox"></light-box>
  </div>
</template>


<script>
import {
  BCard, BRow,BCol
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import EventInfo from "./EventInfo.vue";
import EventFileUpload from "./EventFileUpload.vue";
import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import Chat from '../eventroom/chat/Chat.vue';
import VideoChat from '../eventroom/video/VideoChat.vue'
import EventHeader from "@/views/bookings/eventroom/header/EventHeader";

export default {
  components: {
    EventHeader,
    BRow,
    BCol,
    BCard,
    EventInfo,
    EventFileUpload,
    LightBox,
    Chat,
    VideoChat
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      currentItem: {},
      loaded: true,
      layout: "",
      layouts:"",
      draggable: true,
      resizable: false,
      responsive: true,
      index: 0,
      showLightBox: false,
      media: [],
      setLayout:false,
      dbLayout:[],
    };
  },
  beforeCreate(){
    //
    const spinnerTxt = this.$i18n.locale === 'en' ? 'Loading Event Room' : 'Event Raum wird geladen'

    const appLoadingText = document.getElementById('spinner_txt')
    appLoadingText.innerText = spinnerTxt


  },
  created() {
    this.getCurrentEvent();
    this.setLayout=false
  },

  methods: {
    // Get current event of booking
    getCurrentEvent() {
      let itemUrl = `/experts/bookings/${this.$route.params.bookingid}`;
      this.$http.get(itemUrl)
          .then(response => {
            this.currentItem = response.data
            // this.setPositionOfCard();
          })
          .catch(error => {
          })
    },

    updateItem() {
      this.helperUpdateItem(
        `/bookings/${this.$route.params.id}`,
        this.currentItem
      );
    },

    deleteItem() {
      this.helperDeleteItem(
        `/bookings/${this.$route.params.id}`,
        null,
        "apps-bookings-list"
      );
    },

    onSubmit() {
      this.updateItem();
    },

    // file preview
    viewFileLightBox(e) {
      let fileType = e.type;
      let url = `events/view-file/${e.id}`;
      let accessToken = JSON.parse(
        localStorage.getItem("userData")
      ).access_token;
      let baseUrl =
        process.env.VUE_APP_ROOT_API + url + "?token=" + accessToken;
      if (["image/jpeg", "image/png", "image/jpg"].includes(fileType)) {
        this.media = [
          {
            type: "image",
            thumb: baseUrl,
            src: baseUrl,
          },
        ];
        this.showLightBox = true;
      } else {
        window.open(baseUrl, "_blank");
      }
    },

    //close lightbox
    closeLightBox() {
      this.showLightBox = false;
    },

    layoutUpdatedEvent(newLayout){
        this.checkBreakPointAndSetLayout();
        if(this.setLayout){
          this.$http.post('/event/detail/positions',
          {
            event_id: this.currentItem.event_id,
            layout:newLayout,
            breakpoint:this.breakPoint
          })
          .then(response => {
            this.setLayout = true
          })
          .catch(e => {
          });
        }

    }
  },
};
</script>

<style lang="scss">
.vue-grid-item.vue-grid-placeholder {
  background: grey !important;
}
.main-div-grid{
  width:100%;
  height:100%;
}
.vue-grid-item .no-touch { touch-action: auto; }
/*
@media only screen and (max-width: 768px) {
  .vue-grid-item {
    height: fit-content!important;
    width: 100%!important;
    transform: none!important;
    position: relative!important;
    border: 1px solid green;
  }
}
@media only screen and (min-width: 768px) {
  .vue-grid-item {
    height: fit-content!important;
    width: 100%!important;
    transform: none!important;
    position: relative!important;
    border: 1px solid red;
  }
}
@media only screen and (max-width: 501px) {
  .vue-grid-item {
    height: fit-content!important;
    width: fit-content!important;
    transform: none!important;
    position: relative!important;
    border: 1px solid blue;
  }
} */
</style>
