<template>
  <div>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
        <b-card :title="event.event.name" class="text-center h-100">
          <b-card-body class="pt-0">
            <b-card-img v-if="event.event.image" :src="event.event.image.url" class="event-details"></b-card-img>
            <b-card-text class="mt-3 event-content-color">{{ event.event.informations }}</b-card-text>
            <b-card-title class="mb-1" v-if="event.event.eventlocation == 1">
              <b-card-text>{{ event.event.street }}</b-card-text>
              <b-card-text class="event-content-color">{{ event.event.city + "-" + event.event.postal }}</b-card-text>
            </b-card-title>
            <b-card-text v-else> {{ $t('This Event is happening online') }}</b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
        <b-card
            :title="$t('This event will be hosted by')"
            class="text-center h-100"
        >
          <b-card-body class="pt-0 pb-0 mt-3">
            <b-card-img v-if="event.expert.expertdetails.avatar" :src="event.expert.expertdetails.avatar.url"
                        class="expert-details"></b-card-img>
            <b-card-title class="mb-1 mt-2 mb-2 ">{{ event.expert.name }}</b-card-title>
            <b-card-text class="event-content-color">{{ event.expert.expertdetails.slogan }}</b-card-text>

            <b-card-title class="mb-0 mt-2"> {{ $t('Experttopics') }}</b-card-title>
            <b-card-text class="expert-topics">
              <b-badge pill v-for="topic in event.expert.expertdetails.experttopics" :key="topic.id" variant="dark">
                {{ topic.name }}
              </b-badge>
              <b-badge v-if="event.expert.expertdetails.experttopics.length === 0" pill variant="dark">
                {{ $t('The Expert did not choose any Experttopics yet') }}
              </b-badge>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="4" xl="4" class="mb-2">
        <b-card
            :title="$t('Upcoming Appointments')"
            class="text-center h-100"
        >
          <b-card-body class="pt-0 mt-3">
            <b-card-text>
              <b-row>

                <b-col cols="12" v-if="event.timeslots" >
                  <div v-for="(item,index) in event.timeslots" :key="item.id">
                    <feather-icon v-if="index == 0"  class="mr-2" size="2x" icon="CalendarIcon"/>
                    <b-badge pill   v-if="item.is_in_future"  :class="{'mb-2':true, ['ml-4']: index >0}">
                      {{ format(item.start_date) }} - {{
                        format(item.end_date)
                      }}
                    </b-badge>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardGroup,
  BCardText,
  BRow,
  BCol,
  BCardImg,
  BCardTitle,
  BCardBody,
  BBadge,
  VBTooltip,
  BIcon,
  BButton,
} from "bootstrap-vue";
import moment from 'moment'

export default {
  components: {
    BCard,
    BCardGroup,
    BCardText,
    BRow,
    BCol,
    BCardImg,
    BCardTitle,
    BCardBody,
    BBadge,
    VBTooltip,
    BIcon,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
    event: {
      type: Object,
      default: () => {
      },
    },
  },
  methods: {
    format(date) {
      return moment(String(date))
          .format('DD.MM.YYYY HH:mm')
    }


  },

  mounted() {
  }
};
</script>

<style lang="scss" scoped>

[dir] .card .card{
         box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
       }

.event-details {
  height: 150px;
  background: lightblue;
}

.expert-details {
  border-radius: 50%;
  width: 125px;
  height: 125px;
  background: lightblue;
}

.expert-topics {
  display: flex;
  gap: 13px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 13px;
  justify-content: center;
}

.event-infos-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

.badge {
  background-color: #eeeeee;
  color: inherit;
}

.event-content-color {
  color: #b9b6b7;
}
</style>
