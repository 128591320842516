<template>
  <b-card class="event-file-upload-card h-100">
    <template #header>
      <h4 class="mb-0">{{ $t('File Upload') }}</h4>
      <div>
        <!-- <feather-icon class="mr-1 vue-draggable-handle" size="1x" icon="MoveIcon" v-b-tooltip.hover title="Move Card" />
      <feather-icon
          @click="minMaxSize"
          size="1x"
          :icon="isOriginalSize == true ? 'PlusIcon' : 'MinusIcon'"
          v-b-tooltip.hover
          :title="isOriginalSize == true ? 'Maximise' : 'Minimise'"
      /> -->
      </div>
    </template>
    <b-card-text class="event-content-color">
      {{ $t('If you would like to use Files during the Event, you can upload your Files in here.') }}
    </b-card-text>
    <b-table class="position-relative" responsive :fields="tableColumns" :per-page="perPage" :current-page="currentPage"
             :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="items"
    >
      <!-- Column: Actions -->
      <template v-slot:head(actions)="data">
        <div class="action-header">
          <span class="text-center">{{ data.label }}</span>
          <feather-icon class="text-primary float-right" @click="togglecardHeight" size="1.5x"
                        :icon="perPage == 3 ? 'ChevronDownIcon' : 'ChevronUpIcon'" v-b-tooltip.hover
                        :title="perPage == 3 ? $t('Show More') : $t('Show Less')"
          />
        </div>
      </template>

      <template #cell(actions)="data">
        <feather-icon class="mr-1 text-success" @click="viewFile(data.item)" v-b-tooltip.hover :title="$t('View File')"
                      icon="EyeIcon"
        />

        <feather-icon class="text-danger" v-if="data.item.user_id == login_user.id" @click="deleteFile(data.item)"
                      v-b-tooltip.hover :title="$t('Delete File')" icon="TrashIcon"
        />
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            {{ $t('Showing') }}
            <span v-if="items.length === 0"> {{ items.length }}</span>
            <span v-else-if="items.length > 0"> {{ perPage * (currentPage - 1) + (perPage ? 1 : 0) }}</span>
            {{ $t('to') }}
            <span v-if="items.length < perPage - 1"> {{ items.length }}</span>
            <span v-else-if="perPage * (currentPage) > items.length"> {{ items.length }}</span>
            <span v-else> {{ perPage * (currentPage - 1) + perPage }}</span>
            {{ $t('of') }} {{ items.length }} {{ $t('entries') }}
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" first-number last-number
                        class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <vue-dropzone ref="refFileEl" id="dropzone" :key="componentKey" :options="dropzoneOptions"
                  class="vue-dropzone dropzone-style" :src="event.pictures" :destroyDropzone="false"
                  v-on:vdropzone-removed-file="deleteFile" v-on:vdropzone-success="showSuccess"
                  v-on:vdropzone-error="uploadFailed"
    >
    </vue-dropzone>

    <b-row>
      <b-col cols="12" class="text-right">
        <input type="file" ref="file" style="display: none">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" variant="success" type="button"
                  @click="fileUpload"
        >
          {{ $t('Upload Files') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BButton, BCard, BCardText, BCol, BPagination, BRow, BTable, VBTooltip, } from 'bootstrap-vue'

import vue2Dropzone from 'vue2-dropzone'
import Ripple from 'vue-ripple-directive'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    VBTooltip,
    BButton,
    vueDropzone: vue2Dropzone,
    BPagination,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    event: {
      type: Object,
      default: () => {
      },
    },
    dimension: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${process.env.VUE_APP_ROOT_API}bookings/files/${this.event.id}/fileupload`,
        paramName: 'files',
        params: {},
        thumbnailWidth: 160,
        thumbnailHeight: 120,
        maxFilesize: 10,
        headers: this.getFileUploadHeader(),
        addRemoveLinks: true,
        dictRemoveFile: this.$i18n.t('Delete image'),
        acceptedFiles: '.jpg,.jpeg,.pdf,.png',
        destroyDropzone: false,
        dictDefaultMessage: this.$i18n.t('Drop files here or click to upload'),
        dictFallbackMessage: this.$i18n.t(
            'Your browser does not support drag\'n\'drop file uploads.'
        ),
        dictFallbackText: this.$i18n.t(
            'Please use the fallback form below to upload your files like in the olden days.'
        ),
        dictInvalidFileType: this.$i18n.t(
            'You can\'t upload files of this type.'
        ),
        dictCancelUpload: this.$i18n.t('Cancel upload'),
        dictCancelUploadConfirmation: this.$i18n.t(
            'Are you sure you want to cancel this upload?'
        ),
        dictMaxFilesExceeded: this.$i18n.t(
            'You can not upload any more files.'
        ),
      },
      login_user: JSON.parse(localStorage.getItem('userData')),
      sortBy: 'id',
      sortDesc: true,
      perPage: 3,
      totalRows: 0,
      items: [],
      tableColumns: [
        {
          key: 'name',
          label: this.$i18n.t('File Name'),
          formatter: this.formatFileName,
        },
        {
          key: 'extension',
          label: this.$i18n.t('File Type'),
          formatter: this.formatFileType,
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions'),
          class: 'table-action-buttons'
        },
      ],
      isOriginalSize: true,
      isOriginalHeight: true,
      currentPage: 1,
      componentKey: 0,
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    fileUpload() {
      document.getElementsByClassName('dropzone')[0].click()
    },
    getItems() {
      this.items = []
      this.helperGetItems(`/bookings/files/${this.event.id}/filelist`)
    },

    formatFileName(value, key, item) {
      if (item.name != null) return item.name.replace(/\.[^/.]+$/, '')
    },

    formatFileType(value, key, item) {
      if (item.extension != null) return item.extension.toUpperCase()
    },

    getFileUploadHeader() {
      let token = localStorage.getItem('accessToken')
      return {
        Authorization: 'Bearer ' + token,
      }
    },

    //Upload success
    showSuccess(file, response) {
      // console.log(response);debugger
      // file.id = response.media.id;
      this.getItems()
      this.rerenderDropzone()
    },
    uploadFailed(file, message, xhr) {
      this.rerenderDropzone()
      this.$bvToast.toast(this.$i18n.t(message), {
        title: this.$i18n.t('Error'),
        variant: 'danger',
        toaster: 'b-toaster-bottom-right',
      })
    },
    rerenderDropzone() {
      setTimeout(() => {
        this.componentKey += 1
      }, 1000)
    },

    // delete file
    deleteFile(file) {
      let self = this

      if (file.id) {
        let url = `/bookings/files/${this.event.id}/fileremove/${file.id}`
        this.$http
            .delete(url)
            .then(function (response) {
              self.$bvToast.toast(self.$i18n.t('File deleted'), {
                title: self.$i18n.t('Success'),
                variant: 'success',
                toaster: 'b-toaster-bottom-right',
              })
              self.getItems()
            })
            .catch((e) => {
              this.$bvToast.toast(this.$i18n.t('Image not deleted'), {
                title: this.$i18n.t('Error'),
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
              })
            })
      }
    },
    // preview files
    viewFile(file) {
      this.$emit('file-display', file)
    },
    // card min and max
    minMaxSize() {
      this.$emit('min-max-file-upload', {
        index: 1,
        max: this.isOriginalSize
      })
      this.isOriginalSize = !this.isOriginalSize
    },
    //toggle card heifht
    togglecardHeight() {
      if (this.items.length > 3) {
        this.perPage = (this.perPage == 3) ? 10 : 3
        this.$emit('toggle-card-height', {
          index: 1,
          increase: this.isOriginalHeight,
          height: this.items.length
        })
        this.isOriginalHeight = !this.isOriginalHeight
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.event-file-upload-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

.event-content-color {
  color: #b9b6b7;
}

.dropzone-style {
  border: dashed;
}

.trash-color {
  stroke: red;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.dropzone {
  background: #f9f7f8;
}

.action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
